
























import {computed, defineComponent, PropType} from '@vue/composition-api';
import Institution from '@/institutions/models/Institution';
import {InstitutionScope} from '@/tasks/models/InstitutionScope';
import {ScopeEvents} from '@/tasks/types/ScopeEvents';

export default defineComponent({
  name: 'InstitutionScopeExpansionPanel',
  props: {
    scope: {
      type: Object as PropType<InstitutionScope>,
      required: true,
    },
    index: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  emits: Object.values(ScopeEvents),
  setup(props, {emit}) {
    const availableInstitutionsById = computed(() => {
      const institutions = Institution.query().all();
      return institutions.reduce((institutionsById: Record<number, Institution>, institution) => {
        institutionsById[institution.id] = institution;
        return institutionsById;
      }, {});
    });

    function emitRemove() {
      emit(ScopeEvents.SCOPE_REMOVE_SCOPE, props.index);
    }

    return {
      availableInstitutionsById,
      emitRemove,
    };
  },
});
