import {ref, Ref, watch, WatchCallback, WatchOptions} from '@vue/composition-api';

export interface UseEditableObjectOptions<Editable, Watched = Editable> {
  watchedRef: Ref<Watched>;
  cloneFn: (value?: Watched) => Editable;
  watchNulls?: boolean;
  watchOptions?: WatchOptions;
  editableEntityWatchOptions?: WatchOptions;
  editableEntityWatcherFn?: WatchCallback<Editable, Editable | undefined>;
}

export function useEditableObject<Editable, Watched = Editable>({
  watchedRef,
  cloneFn,
  watchOptions,
  watchNulls,
  editableEntityWatcherFn,
  editableEntityWatchOptions,
}: UseEditableObjectOptions<Editable, Watched>) {
  let isCloning = false;

  const editableEntity: Ref<Editable> = ref(cloneFn(watchedRef.value)) as Ref<Editable>;

  function clear() {
    editableEntity.value = cloneFn();
  }

  function reset() {
    const value = watchedRef.value;
    if (value) {
      watchHandler(value);
    } else {
      clear();
    }
  }

  function watchHandler(newValue: Watched) {
    if (newValue !== null || watchNulls) {
      isCloning = true;
      editableEntity.value = cloneFn(newValue);
    }
  }

  watch(watchedRef, watchHandler, watchOptions);
  if (editableEntityWatcherFn) {
    watch(
      editableEntity,
      (...args) => {
        if (!isCloning) {
          editableEntityWatcherFn?.(...args);
        }
        isCloning = false;
      },
      editableEntityWatchOptions
    );
  }

  return {
    watchedRef,
    editableEntity,
    clear,
    reset,
  };
}
