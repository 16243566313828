import {useEditableObject, UseEditableObjectOptions} from '@/common/composables/useEditableObject';
import {computed, Ref} from '@vue/composition-api';

export type EntityEditorOptions<
  Editable,
  Watched = Editable,
  CreateReturn = any,
  PatchReturn = any,
> = UseEditableObjectOptions<Editable, Watched> & {
  createFn?: (entity: Editable) => CreateReturn;
  patchFn?: (entity: Editable) => PatchReturn;
  isNewRef?: Ref<boolean>;
};

export function useEntityEditor<
  Editable,
  Watched = Editable,
  CreateReturn = any,
  PatchReturn = any,
>({
  watchedRef,
  createFn,
  patchFn,
  isNewRef,
  ...editableOptions
}: EntityEditorOptions<Editable, Watched, CreateReturn, PatchReturn>) {
  const editableFunctions = useEditableObject({watchedRef, ...editableOptions});

  function create(): CreateReturn | undefined {
    if (createFn) {
      return createFn(editableFunctions.editableEntity.value);
    }
  }

  function patch(): PatchReturn | undefined {
    if (patchFn) {
      return patchFn(editableFunctions.editableEntity.value);
    }
  }

  function save() {
    if (isNewRef?.value ?? watchedRef.value === null) {
      return create();
    } else {
      return patch();
    }
  }

  return {
    ...editableFunctions,
    create,
    patch,
    save,
    isNew: isNewRef ?? computed(() => watchedRef.value === null),
  };
}
