import {inject, makeDependency} from '@/container';
import {UseRouteTask} from '@/tasks/composables/UseRouteTask';
import Task from '@/tasks/models/Task';
import {computed, ref, Ref} from '@vue/composition-api';
import {useEntityEditor} from '@/common/composables/useEntityEditor';
import TaskTopic from '@/tasks/models/TaskTopic';
import TaskFeedback from '@/tasks/models/TaskFeedback';
import {DependencyType} from '@/container/types/DependencyType';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {LoadingFlagsService} from '@/loading/services/LoadingFlagsService';
import {ErrorReportingService} from '@/errors/services/error-reporting';
import Institution from '@/institutions/models/Institution';
import {TaskScopeContent} from '@/tasks/models/TaskScopeContent';
import {TaskScopeType} from '@/tasks/types/TaskScopeType';
import {InstitutionScope} from '@/tasks/models/InstitutionScope';

export const UseTaskEditor = makeDependency((task?: Ref<Task | null>) => {
  if (!task) {
    task = inject(UseRouteTask, {
      taskFetcher(id) {
        Task.api.get(id, {
          includeTopics: true,
          includeTaskTypes: true,
          includeScopes: true,
        });
        TaskFeedback.api.fetch({task: id});
        Institution.api.fetch();
      },
      taskGetter(id) {
        return Task.fullQuery({
          taskFeedbacks: true,
          scopes: true,
        })
          .whereId(id)
          .first();
      },
    }).task;
  }
  return makeTaskEditor(task, inject(LoadingFlagsService), inject(ErrorReportingService));
});
export function makeTaskEditor(
  task: Ref<Task | null>,
  $loadingFlags: DependencyType<typeof LoadingFlagsService>,
  $errorReporting: DependencyType<typeof ErrorReportingService>
) {
  const isPatching = computed(() => $loadingFlags.isLoading(LoadingFlag.TaskEditorPatchTask));
  const expandedFeedbackPanel: Ref<number | null> = ref(null);
  const expandedScopePanel: Ref<number | null> = ref(null);

  function refInitializer(): Task {
    return new Task({
      id: 0,
      title: '',
      textbookLink: null,
      youtubeVideoId: null,
      topicIds: [],
      taskFeedbacks: [],
      scopes: [],
    });
  }

  function cloneFn(task?: Task | null): Task {
    if (task) {
      return (
        Task.fullQuery({
          taskFeedbacks: true,
          feedback: true,
          scopes: true,
        })
          .where('id', task.id)
          .first() ?? refInitializer()
      );
    }
    return refInitializer();
  }

  async function patchFn(editableTask: Task) {
    await $loadingFlags
      .loadingHandler(LoadingFlag.TaskEditorPatchTask, async () => {
        await Task.api.patch(editableTask.id, {
          ...editableTask,
          feedbacks: editableTask.taskFeedbacks,
          scopes: editableTask.scopes,
        });
        await TaskFeedback.api.fetch({task: editableTask.id});
      })
      .catch($errorReporting.errorDialogHandler);
  }

  const {watchedRef, editableEntity, patch, clear, reset} = useEntityEditor({
    watchedRef: task,
    cloneFn,
    patchFn,
  });

  function addFeedback() {
    editableEntity.value.taskFeedbacks.push(new TaskFeedback());
    expandedFeedbackPanel.value = editableEntity.value.taskFeedbacks.length - 1;
  }

  function removeFeedback(index: number) {
    editableEntity.value.taskFeedbacks.splice(index, 1);
  }

  function addScope<T extends TaskScopeType>(type: T) {
    const newScope = new TaskScopeContent({type});
    if (type === TaskScopeType.InstitutionScope) {
      newScope.content = new InstitutionScope();
    }

    if (newScope.content) {
      editableEntity.value.scopes.push(newScope);
      expandedScopePanel.value = editableEntity.value.scopes.length - 1;
    }
  }

  function removeScope(index: number) {
    editableEntity.value.scopes.splice(index, 1);
  }

  const selectedTopics = computed(() => {
    return editableEntity.value.topicIds
      .map((id) => {
        return TaskTopic.find(id);
      })
      .filter((item) => item !== null);
  });

  return {
    task: watchedRef,
    editableTask: editableEntity,
    isPatching,
    patch,
    clear,
    reset,
    selectedTopics,
    addFeedback,
    removeFeedback,
    expandedFeedbackPanel,
    addScope,
    removeScope,
    expandedScopePanel,
  };
}
