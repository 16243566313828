import {inject, makeDependency} from '@/container';
import {computed, Ref} from '@vue/composition-api';
import {Route} from '@/router/composables';
import Task from '@/tasks/models/Task';
import {useEntityFromId} from '@/common/composables/useEntityFromId';

export type UseRouteTaskOptions = {
  taskId?: Ref<number>;
  taskGetter?: (taskId: number) => Task | null;
  taskFetcher?: (taskId: number) => void;
};

export const UseRouteTask = makeDependency((options: UseRouteTaskOptions = {}) => {
  if (!options.taskId) {
    const route = inject(Route);
    options.taskId = computed(() => parseInt(route.params.taskId));
  }
  return makeRouteTask(options.taskId, options);
});

export function makeRouteTask(taskId: Ref<number>, options: Omit<UseRouteTaskOptions, 'taskId'>) {
  if (!options.taskGetter) {
    options.taskGetter = (taskId) => {
      return Task.fullQuery().whereId(taskId).first() as Task | null;
    };
  }

  if (!options.taskFetcher) {
    options.taskFetcher = (taskId) => {
      return Task.api.get(taskId);
    };
  }

  const {entity, fetch} = useEntityFromId(taskId, options.taskGetter, options.taskFetcher);

  return {
    task: entity,
    fetchTask: fetch,
  };
}
