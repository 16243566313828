import {computed, Ref, watch} from '@vue/composition-api';
import {Model} from '@vuex-orm/core';

export function useEntityFromId<M extends Model>(
  id: Ref<number>,
  getter: (id: number) => M | null,
  fetcher: (id: number) => void
) {
  const entity = computed(() => {
    return getter(id.value);
  });

  async function fetch() {
    fetcher(id.value);
  }

  watch(id, fetch, {immediate: true});

  return {
    entity,
    fetch,
  };
}
